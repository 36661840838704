import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EndpointBuilder } from "../../core/endpoints/endpoint-builder";
import { IApiResponse } from "../../domain/api-response";
import { IUser } from "../../domain/user";
import { IUserModel } from "../../models/user-model";
import { ICourse } from "../../domain/course";
import { ICourseModel } from "../../models/course-model";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  private static endpoint = EndpointBuilder.buildDomainEndpoint("profile");
  constructor(private readonly httpClient: HttpClient) {}

  getProfile(): Observable<IApiResponse<IUser>> {
    return this.httpClient.get<IApiResponse<IUser>>(ProfileService.endpoint);
  }

  getCourse(): Observable<IApiResponse<ICourse>> {
    return this.httpClient.get<IApiResponse<ICourse>>(
      ProfileService.endpoint + "/course"
    );
  }

  updateProfile(model: IUserModel): Observable<IApiResponse<IUser>> {
    return this.httpClient.patch<IApiResponse<IUser>>(
      ProfileService.singleEndpoint(),
      model
    );
  }

  createProfile(model: IUserModel): Observable<IApiResponse<IUser>> {
    return this.httpClient.post<IApiResponse<IUser>>(
      ProfileService.singleEndpoint(),
      model
    );
  }

  addCourse(model: ICourseModel): Observable<IApiResponse<ICourse>> {
    return this.httpClient.post<IApiResponse<ICourse>>(
      ProfileService.singleEndpoint() + "/course",
      model
    );
  }

  uploadProfilePicture(
    image: File,
    userId: string
  ): Observable<IApiResponse<string>> {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("userId", userId);
    return this.httpClient.patch<IApiResponse<string>>(
      ProfileService.singleEndpoint() + "/picture",
      formData
    );
  }

  private static singleEndpoint() {
    return ProfileService.endpoint;
  }
}
