import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { routing } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MenuModule } from "primeng/menu";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MenubarModule } from "primeng/menubar";
import { NavigationModule } from "./navigation/navigation.module";
import { DesignModule } from "./design/design.module";
import { MainModule } from "./main/main.module";
import { NgxsModule } from "@ngxs/store";
import { environment } from "../environments/environment";
import { NgxsDispatchPluginModule } from "@ngxs-labs/dispatch-decorator";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { ProfessionsCoreModule } from "./professions-core/professions-core.module";
import { registerLocaleData } from "@angular/common";
import locale from "@angular/common/locales/de-CH";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";

registerLocaleData(locale);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    routing,
    BrowserAnimationsModule,
    MenuModule,
    MenubarModule,
    NavigationModule,
    DesignModule,
    HttpClientModule,
    MainModule,
    ProfessionsCoreModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    NgxsDispatchPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    AuthModule.forRoot({
      cacheLocation: "localstorage",
      domain: "lwzo.eu.auth0.com",
      issuer: "https://lwzo.eu.auth0.com/",
      clientId: "TEwirtSnFrE5ErU97RyXM6rRDL7KKAH0",
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https://backend.lwzo.ch",
      },
      httpInterceptor: {
        allowedList: ["/api/*", "http://localhost:8080/api/*", "https://backend.lwzo.ch/api/*"],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "de-CH" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
