import { Component, Inject, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { AuthService } from "@auth0/auth0-angular";
import { catchError, combineLatestWith, filter, of, tap } from "rxjs";
import { AuthenticationService } from "../../../core/services/authentication.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly authenticationService: AuthenticationService,
    @Inject(DOCUMENT) public document: Document
  ) {}
  items: MenuItem[] = this.getLoggedOutItems();
  visible: boolean = true;

  ngOnInit() {
    this.authService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => Boolean(isAuthenticated)),
        combineLatestWith(this.authenticationService.isAdmin()),
        tap(([isAuthenticated, isAdmin]) => {
          if (isAuthenticated) {
            this.items = this.getLoggedInItems(isAdmin);
          } else {
            this.items = this.getLoggedOutItems();
          }
        }),
        catchError((_) => {
          this.items = this.getLoggedOutItems();
          return of(null);
        })
      )
      .subscribe((_) => {
        this.updateVisibility();
      });
  }

  private updateVisibility(): void {
    this.visible = false;
    setTimeout(() => (this.visible = true), 0);
  }

  private getLoggedOutItems(): MenuItem[] {
    return [
      {
        label: "Startseite",
        routerLink: "/register",
        icon: "pi pi-home",
      },
      {
        label: "Login",
        icon: "pi pi-sign-in",
        routerLink: "/login",
      },
      {
        label: "Impressum",
        routerLink: "/about",
        icon: "pi pi-info",
      },
    ];
  }

  private getLoggedInItems(isAdmin: boolean): MenuItem[] {
    return [
      {
        label: "Startseite",
        routerLink: "/home",
        icon: "pi pi-home",
        visible: !isAdmin,
      },
      {
        label: "Meine Daten",
        routerLink: "/profile",
        icon: "pi pi-chevron-circle-right",
        visible: !isAdmin,
      },
      {
        label: "Meine Firma",
        routerLink: "/companies/own",
        icon: "pi pi-users",
        visible: !isAdmin,
      },
      {
        label: "Mein Projekt",
        routerLink: "/projects/own",
        icon: "pi pi-shopping-cart",
        visible: !isAdmin,
      },
      {
        label: "Teilnehmer",
        routerLink: "/users",
        icon: "pi pi-calendar",
        visible: isAdmin,
      },
      {
        label: "Projekte",
        routerLink: "/projects",
        icon: "pi pi-shopping-cart",
        visible: isAdmin,
      },
      {
        label: "Firmen",
        routerLink: "/companies",
        icon: "pi pi-users",
        visible: isAdmin,
      },
      {
        label: "Berufe",
        routerLink: "/professions",
        icon: "pi pi-info",
        visible: isAdmin,
      },
      {
        label: "Experten",
        routerLink: "/experts",
        icon: "pi pi-info",
        visible: isAdmin,
      },
      {
        label: "Statistiken",
        routerLink: "/stats",
        icon: "pi pi-info",
        visible: isAdmin,
      },
      {
        label: "Exporte",
        routerLink: "/exports",
        icon: "pi pi-info",
        visible: isAdmin,
      },
      {
        label: "Impressum",
        routerLink: "/about",
        icon: "pi pi-info",
        visible: !isAdmin,
      },
      {
        label: "Logout",
        icon: "pi pi-chevron-circle-right",
        command: () => {
          this.authService.logout({
            logoutParams: { returnTo: document.location.origin },
          });
        },
      },
    ];
  }
}
