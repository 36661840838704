import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from "./app/app.module";
import { ExtraErrorData, HttpClient } from "@sentry/integrations";

Sentry.init({
  dsn: "https://0fb50ea52a66be327f83acc5e0351f7b@o4506015135694848.ingest.sentry.io/4506168098684929",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new ExtraErrorData(),
    new HttpClient(),
  ],
  tracesSampleRate: 0.1,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
