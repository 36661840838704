import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ProfileFacade } from "../state/profile.facade";

@Injectable({
  providedIn: "root",
})
export class CanActivateProfile  {
  constructor(private readonly profileFacade: ProfileFacade) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.profileFacade.loadProfile$();
  }
}
