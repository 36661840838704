import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileService } from "./services/profile.service";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { ProfileFacade } from "./state/profile.facade";
import { NgxsModule } from "@ngxs/store";
import { ProfileState } from "./state/profile.state";
import { CoreModule } from "../core/core.module";
import { CanActivateProfile } from "./guards/can-activate-profile.service";

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forFeature([ProfileState]),
    CommonModule,
    ReactiveFormsModule,
    NgxsFormPluginModule,
    CoreModule,
  ],
  providers: [ProfileFacade, ProfileService, CanActivateProfile],
})
export class ProfileCoreModule {}
