import { Component, DestroyRef, OnInit } from "@angular/core";
import {
  AppState,
  AuthService,
  RedirectLoginOptions,
} from "@auth0/auth0-angular";
import { Router } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AuthenticationService } from "../../../core/services/authentication.service";

@Component({
  selector: "register",
  templateUrl: "./register.page.html",
  styleUrls: ["register.page.scss"],
})
export class RegisterPage implements OnInit {
  constructor(
    readonly authService: AuthService,
    private readonly x: AuthenticationService,
    private readonly router: Router,
    private readonly destroyRef: DestroyRef
  ) {}

  getLoginOptions(): RedirectLoginOptions<AppState> {
    return {
      appState: { target: "/home" },
    };
  }

  ngOnInit(): void {
    this.authService.isAuthenticated$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.router.navigate(["home"]).then();
        }
      });
  }
}
