import { Injectable } from "@angular/core";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { ProfileState } from "./profile.state";
import {
  AddCourse,
  CreateProfile,
  LoadProfile,
  UpdateProfile,
} from "./profile.actions";
import { IUser } from "../../domain/user";
import { AppState } from "../../core/app-state";
import { ICourse } from "../../domain/course";

@Injectable()
export class ProfileFacade {
  constructor(private readonly appState: AppState) {}
  @Select(ProfileState.profile)
  profile: Observable<IUser>;
  @Select(ProfileState.course)
  course: Observable<ICourse>;
  @Select(ProfileState.hasNoCourse)
  hasNoCourse: Observable<boolean>;
  @Select(ProfileState.hasProfile)
  hasProfile: Observable<boolean>;
  @Select(ProfileState.hasProfileImage)
  hasProfileImage: Observable<boolean>;
  @Select(ProfileState.loading)
  loading: Observable<boolean>;
  @Select(ProfileState.errorMessage)
  errorMessage: Observable<string>;

  @Dispatch()
  loadProfile = () => new LoadProfile();
  loadProfile$ = () => this.appState.dispatch(new LoadProfile());
  @Dispatch()
  updateProfile = () => new UpdateProfile();
  @Dispatch()
  createProfile = () => new CreateProfile();
  @Dispatch()
  addCourse = () => new AddCourse();
}
