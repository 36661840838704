import { Component } from "@angular/core";
import {
  AppState,
  AuthService,
  RedirectLoginOptions,
} from "@auth0/auth0-angular";

@Component({
  selector: "login",
  templateUrl: "./login.page.html",
})
export class LoginPage {
  constructor(public readonly authService: AuthService) {}

  getLoginOptions(): RedirectLoginOptions<AppState> {
    return {
      appState: { target: "/home" },
    };
  }
}
