import { Injectable } from "@angular/core";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Select } from "@ngxs/store";
import { map, Observable } from "rxjs";
import { ProfessionState } from "./profession.state";
import {
  AddProfession,
  DeleteProfession,
  LoadProfessions,
  UpdateProfession,
} from "./profession.actions";
import { AppState } from "../../core/app-state";
import { IProfession } from "../../domain/profession";

@Injectable()
export class ProfessionFacade {
  @Select(ProfessionState.professions)
  professions: Observable<IProfession[]>;
  @Select(ProfessionState.professionsList)
  professionsList: Observable<IProfession[]>;
  @Select(ProfessionState.loading)
  loading: Observable<boolean>;
  @Select(ProfessionState.errorMessage)
  errorMessage: Observable<string>;

  constructor(private readonly appState: AppState) {}

  byId$(id: string) {
    return this.appState
      .select$(ProfessionState.profession)
      .pipe(map((selector) => selector(id)));
  }

  @Dispatch()
  loadProfessions = () => new LoadProfessions();
  loadProfessions$ = () => this.appState.dispatch(new LoadProfessions());

  @Dispatch()
  addProfession = () => new AddProfession();

  @Dispatch()
  deleteProfession = (professionId: string) =>
    new DeleteProfession(professionId);

  @Dispatch()
  updateProfession = (professionId: string) =>
    new UpdateProfession(professionId);
}
