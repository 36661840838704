<app-header [title]="'Für den Lehrlingswettbewerb anmelden'"></app-header>
<div class="content">
  <p>Es freut uns, dass du dich für den Lehrlingswettbewerb interssierst. </p>
  <!--<p><b>Die Anmeldungsfrist für dieses Jahr ist vorbei. Melde dich direkt bei der Geschäftsstelle.</b></p>-->
  <p>Um dein Projekt anzumelden, brauchst du ein Login für diese Webseite. Dies kannst du über Login in der Navigationsleiste erstellen.</p>
  <p>Klicke anschliessend auf den Button "Login" und dann unten am weissen Fenster auf "Registrieren". <br/>
    Gib deine Mailadresse ein und wähle anschliessend ein sicheres Passwort. Das Tool untersützt dich dabei. </p>
  <img src="../../../../assets/register.png" height="500"/>
  <p>Sobald du Fortfahren geklickt hast, wird dein Konto erstellt und du automatisch in der Platform eingeloggt. Dort wirst du weiter durch die Anmeldung geführt.</p>
  <p><b>WICHTIG</b>: Mit dem Erstellen des Logins und der Anmeldung bist du mit dem <a href="https://lehrlingswettbewerb.ch/teilnehmende/reglement/">Reglement</a> zum Lehrlingswettbewerb einverstanden <br/>
    und erlaubst dem Lehrlingswettbewerb die Daten im Rahmen der Tätigkeiten rund um den Wettbewerb zu nutzen.</p>
  <app-button [disabled]="false" icon="sign-in" label="Login" buttonStyle="outlined" (click)="authService.loginWithRedirect(getLoginOptions())"></app-button>
  <p>Bei Fragen wende dich an die Geschäftsstelle. Die Angaben dazu kannst du im Impressum finden. </p>
</div>