<app-header [title]="'Impressum'"></app-header>
<div class="content">
  <h3>Fragen und technische Unterstützung</h3>
  <p>Simon Boller<br>079 328 32 38<br><a href="mailto:simon.boller@lwzo.ch">simon.boller&#64;lwzo.ch</a></p>

  <h3>Design und Umsetzung</h3>
  <p>Urs Forrer<br/>Simon Boller</p>

  <h3>Hosting</h3>
  <p>Die Anwendung wird auf Servern in der Schweiz gehostet.</p>

  <h3>Hinter der Webseite</h3>
  <p>Die Webseite wird mit Angular and .NET Core betrieben. Das Template basiert auf dem PrimgeNG. Die Icons kommen ebenfalls von dort. Die Schriften werden über Google Fonts genutzt.</p>

  <h3 class="ui header">Copyright</h3>
  <p>Diese Website und alle dazugehörigen Texte, Grafiken, Logos sowie andere Materialien und das Design sind urheberrechtlich geschützt.<br>Jegliche Verwendung ohne vorherige schriftliche Zustimmung durch den Webmaster der Seite ist nicht gestattet.</p>
</div>
