import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { MenubarModule } from "primeng/menubar";
import { ProfileCoreModule } from "../profile-core/profile-core.module";
import { CurrentUserComponent } from "./components/current-user/current-user.component";

@NgModule({
  declarations: [NavigationComponent, CurrentUserComponent],
  exports: [NavigationComponent, CurrentUserComponent],
  imports: [CommonModule, MenubarModule, ProfileCoreModule],
})
export class NavigationModule {}
