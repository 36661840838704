import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  AddProfession,
  DeleteProfession,
  LoadProfessions,
  ResetErrorMessage,
  UpdateProfession,
} from "./profession.actions";
import { ProfessionService } from "../services/profession.service";
import { catchError, finalize, of, tap } from "rxjs";
import { ProfessionStateModel } from "./profession-state-model";
import { ResetForm } from "@ngxs/form-plugin";
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from "@ngxs/store/operators";
import { Navigate } from "@ngxs/router-plugin";
import { IProfession } from "../../domain/profession";

const defaults: ProfessionStateModel = {
  data: [],
  errorMessage: "",
  loading: false,
  editProfessionForm: {
    model: undefined,
    dirty: false,
    status: "",
    errors: {},
  },
  addProfessionForm: {
    model: undefined,
    dirty: false,
    status: "",
    errors: {},
  },
};

@State<ProfessionStateModel>({
  name: "professions",
  defaults,
})
@Injectable()
export class ProfessionState {
  @Selector() static errorMessage(state: ProfessionStateModel) {
    return state.errorMessage;
  }

  @Selector() static loading(state: ProfessionStateModel) {
    return state.loading;
  }

  @Selector() static professions(state: ProfessionStateModel) {
    return state.data;
  }

  @Selector() static professionsList(state: ProfessionStateModel) {
    return state.data.map((profession) => {
      return { ...profession };
    });
  }

  @Selector() static profession(state: ProfessionStateModel) {
    return (id: string) => state.data.find((e) => e.id === id);
  }

  constructor(private readonly professionService: ProfessionService) {}

  @Action(ResetErrorMessage)
  resetErrorMessage(
    { patchState }: StateContext<ProfessionStateModel>,
    {}: ResetErrorMessage
  ) {
    patchState({
      errorMessage: "",
    });
  }

  @Action(LoadProfessions)
  loadGroups(
    { patchState, dispatch }: StateContext<ProfessionStateModel>,
    {}: LoadProfessions
  ) {
    patchState({ loading: true });
    return this.professionService.getProfessions().pipe(
      tap((response) => {
        patchState({
          data: Boolean(response.data) ? response.data : [],
          loading: false,
        });
        dispatch(new ResetErrorMessage());
      }),
      catchError((error) => {
        patchState({ errorMessage: error, loading: false });
        return of([]);
      })
    );
  }

  @Action(AddProfession)
  addGroup(context: StateContext<ProfessionStateModel>, {}: AddProfession) {
    context.patchState({ loading: true });
    let formValues = context.getState().addProfessionForm.model;
    return this.professionService.createProfession(formValues).pipe(
      tap((res) => {
        context.setState(
          patch<ProfessionStateModel>({
            data: insertItem<IProfession>(res.data),
          })
        );
        context.dispatch([
          new ResetForm({ path: "professions.addProfessionForm" }),
          new Navigate(["/professions"]),
        ]);
      }),
      catchError((error) => {
        context.patchState({ errorMessage: error, loading: false });
        return of([]);
      }),
      finalize(() => {
        context.patchState({ loading: false });
      })
    );
  }

  @Action(UpdateProfession)
  updateExpert(
    context: StateContext<ProfessionStateModel>,
    action: UpdateProfession
  ) {
    context.patchState({ loading: true });
    let formValues = context.getState().editProfessionForm.model;
    return this.professionService
      .updateProfession(action.professionId, formValues)
      .pipe(
        tap((res) => {
          context.setState(
            patch<ProfessionStateModel>({
              data: updateItem<IProfession>(
                (profession) => profession.id === action.professionId,
                res.data
              ),
            })
          );
          context.dispatch([
            new ResetForm({ path: "professions.editProfessionForm" }),
            new Navigate(["/professions"]),
          ]);
        }),
        catchError((error) => {
          context.patchState({ errorMessage: error, loading: false });
          return of([]);
        }),
        finalize(() => {
          context.patchState({ loading: false });
        })
      );
  }

  @Action(DeleteProfession)
  deleteExpert(
    context: StateContext<ProfessionStateModel>,
    action: DeleteProfession
  ) {
    context.patchState({ loading: true });
    return this.professionService.deleteProfession(action.professionId).pipe(
      tap((_) => {
        context.setState(
          patch<ProfessionStateModel>({
            data: removeItem<IProfession>(
              (profession) => profession.id === action.professionId
            ),
          })
        );
      }),
      catchError((error) => {
        context.patchState({ errorMessage: error, loading: false });
        return of([]);
      }),
      finalize(() => {
        context.patchState({ loading: false });
      })
    );
  }
}
