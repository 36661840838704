import { Pipe, PipeTransform } from "@angular/core";
import { ProfessionFacade } from "../state/profession.facade";
import { map, Observable, take } from "rxjs";
import { Gender } from "../../domain/gender";

@Pipe({
  name: "professionText",
})
export class ProfessionTextPipe implements PipeTransform {
  constructor(private readonly professionFacade: ProfessionFacade) {}

  transform(profession: string, gender: Gender): Observable<string> {
    return this.professionFacade.professions.pipe(
      take(1),
      map((professions) => {
        return professions.find((pr) => pr.id === profession);
      }),
      map((profession) => {
        if (!Boolean(gender) || !Boolean(profession)) {
          return "";
        }
        if (gender == Gender.Female) {
          return profession.femaleTitle;
        } else {
          return profession.maleTitle;
        }
      })
    );
  }
}
