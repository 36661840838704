import { RouterModule, Routes } from "@angular/router";
import { RegisterPage } from "./main/pages/register/register.page";
import { LoginPage } from "./main/pages/login/login.page";
import { AuthGuard } from "@auth0/auth0-angular";
import { AboutPage } from "./main/pages/about/about.page";
import { CanActivateProfessions } from "./professions-core/guards/can-activate-professions.service";

const routes: Routes = [
  { path: "", redirectTo: "register", pathMatch: "full" },
  {
    path: "home",
    canActivate: [AuthGuard],
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  { path: "register", component: RegisterPage },
  { path: "about", component: AboutPage },
  { path: "login", component: LoginPage },
  {
    path: "experts",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./experts/experts.module").then((m) => m.ExpertsModule),
  },
  {
    path: "professions",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./professions/professions.module").then(
        (m) => m.ProfessionsModule
      ),
  },
  {
    path: "sponsors",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./sponsors/sponsors.module").then((m) => m.SponsorsModule),
  },
  {
    path: "companies",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./companies/companies.module").then((m) => m.CompaniesModule),
  },
  {
    path: "projects",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./projects/projects.module").then((m) => m.ProjectsModule),
  },
  {
    path: "stats",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./stats/stats.module").then((m) => m.StatsModule),
  },
  {
    path: "exports",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./exports/exports.module").then((m) => m.ExportsModule),
  },
  {
    path: "profile",
    canActivate: [AuthGuard, CanActivateProfessions],
    loadChildren: () =>
      import("./profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "users",
    canActivate: [AuthGuard, CanActivateProfessions],
    loadChildren: () =>
      import("./users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "projectUserRatings",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./project-user-ratings/project-user-ratings.module").then(
        (m) => m.ProjectUserRatingsModule
      ),
  },
];

export const routing = RouterModule.forRoot(routes);
