import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  AddCourse,
  CreateProfile,
  LoadProfile,
  ResetErrorMessage,
  UpdateProfile,
} from "./profile.actions";
import { ProfileService } from "../services/profile.service";
import { catchError, finalize, forkJoin, of, tap } from "rxjs";
import { ProfileStateModel } from "./profile-state-model";
import { ResetForm } from "@ngxs/form-plugin";
import { Navigate } from "@ngxs/router-plugin";

const defaults: ProfileStateModel = {
  data: null,
  course: null,
  errorMessage: "",
  loading: false,
  editProfileForm: {
    model: undefined,
    dirty: false,
    status: "",
    errors: {},
  },
  createProfileForm: {
    model: undefined,
    dirty: false,
    status: "",
    errors: {},
  },
  addCourseForm: {
    model: undefined,
    dirty: false,
    status: "",
    errors: {},
  },
};

@State<ProfileStateModel>({
  name: "profile",
  defaults,
})
@Injectable()
export class ProfileState {
  @Selector() static errorMessage(state: ProfileStateModel) {
    return state.errorMessage;
  }

  @Selector() static loading(state: ProfileStateModel) {
    return state.loading;
  }

  @Selector() static profile(state: ProfileStateModel) {
    return state.data;
  }

  @Selector() static course(state: ProfileStateModel) {
    return state.course;
  }

  @Selector() static hasNoCourse(state: ProfileStateModel) {
    return !Boolean(state.course);
  }

  @Selector() static hasProfile(state: ProfileStateModel) {
    return Boolean(state.data);
  }

  @Selector() static hasProfileImage(state: ProfileStateModel) {
    return Boolean(state?.data?.profileImage);
  }

  constructor(private readonly profileService: ProfileService) {}

  @Action(ResetErrorMessage)
  resetErrorMessage(
    { patchState }: StateContext<ProfileStateModel>,
    {}: ResetErrorMessage
  ) {
    patchState({
      errorMessage: "",
    });
  }

  @Action(LoadProfile)
  loadProfile(
    { patchState, dispatch }: StateContext<ProfileStateModel>,
    _: LoadProfile
  ) {
    patchState({ loading: true });
    return forkJoin([
      this.profileService.getProfile(),
      this.profileService.getCourse(),
    ]).pipe(
      tap(([profileResponse, courseResponse]) => {
        patchState({
          data: profileResponse.data,
          course: courseResponse.data,
          loading: false,
        });
        dispatch(new ResetErrorMessage());
      }),
      catchError((error) => {
        patchState({ errorMessage: error, loading: false });
        return of([]);
      })
    );
  }

  @Action(UpdateProfile)
  updateProfile(context: StateContext<ProfileStateModel>, _: UpdateProfile) {
    context.patchState({ loading: true });
    let formValues = context.getState().editProfileForm.model;
    return this.profileService.updateProfile(formValues).pipe(
      tap((res) => {
        context.patchState({ data: res.data });
        context.dispatch([
          new ResetForm({ path: "profile.editProfileForm" }),
          new Navigate(["/profile"]),
        ]);
      }),
      catchError((error) => {
        context.patchState({ errorMessage: error, loading: false });
        return of([]);
      }),
      finalize(() => {
        context.patchState({ loading: false });
      })
    );
  }

  @Action(CreateProfile)
  createProfile(context: StateContext<ProfileStateModel>, _: CreateProfile) {
    context.patchState({ loading: true });
    let formValues = context.getState().createProfileForm.model;
    return this.profileService.createProfile(formValues).pipe(
      tap((res) => {
        context.patchState({ data: res.data });
        context.dispatch([
          new ResetForm({ path: "profile.createProfileForm" }),
          new Navigate(["/home"]),
        ]);
      }),
      catchError((error) => {
        context.patchState({ errorMessage: error, loading: false });
        return of([]);
      }),
      finalize(() => {
        context.patchState({ loading: false });
      })
    );
  }

  @Action(AddCourse)
  addCourse(context: StateContext<ProfileStateModel>, _: AddCourse) {
    context.patchState({ loading: true });
    let formValues = context.getState().addCourseForm.model;
    return this.profileService.addCourse(formValues).pipe(
      tap((res) => {
        context.patchState({ course: res.data });
        context.dispatch([
          new ResetForm({ path: "profile.addCourseForm" }),
          new Navigate(["/home"]),
        ]);
      }),
      catchError((error) => {
        context.patchState({ errorMessage: error, loading: false });
        return of([]);
      }),
      finalize(() => {
        context.patchState({ loading: false });
      })
    );
  }
}
