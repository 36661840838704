export class LoadProfile {
  static readonly type = "[Profile] Load profile";
  constructor() {}
}

export class ResetErrorMessage {
  static readonly type = "[Profile] Reset error message";
  constructor() {}
}

export class UpdateProfile {
  static readonly type = "[Profile] Update profile";
  constructor() {}
}

export class CreateProfile {
  static readonly type = "[Profile] Create profile";
  constructor() {}
}

export class AddCourse {
  static readonly type = "[Profile] Add course";
  constructor() {}
}
