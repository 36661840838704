import { Injectable } from "@angular/core";
import { ProfessionFacade } from "../state/profession.facade";
import { forkJoin, map, mergeMap, Observable, of, take, toArray } from "rxjs";

@Injectable()
export class ProfessionsHelper {
  constructor(private readonly professionFacade: ProfessionFacade) {}
  types(): Observable<any[]> {
    return forkJoin([this.emptyOption(), this.getAllProfessions()]).pipe(
      map(([s1, s2]) => [...s1, ...s2])
    );
  }

  private getAllProfessions(): Observable<any[]> {
    return this.professionFacade.professions.pipe(
      take(1),
      mergeMap((professions) => professions),
      map((item) => {
        return {
          name: item.maleTitle + "/" + item.femaleTitle,
          value: item.id,
        };
      }),
      toArray()
    );
  }

  private emptyOption(): Observable<any[]> {
    return of([{ name: "Beruf auswählen", value: "" }]);
  }
}
