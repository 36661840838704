import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RegisterPage } from "./pages/register/register.page";
import { DesignModule } from "../design/design.module";
import { LoginPage } from "./pages/login/login.page";
import { CardModule } from "primeng/card";
import { ButtonModule } from "primeng/button";
import { AboutPage } from "./pages/about/about.page";

@NgModule({
  declarations: [AboutPage, LoginPage, RegisterPage],
  exports: [],
  imports: [CommonModule, DesignModule, CardModule, ButtonModule],
})
export class MainModule {}
