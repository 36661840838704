import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EndpointBuilder } from "../../core/endpoints/endpoint-builder";
import { IApiResponse } from "../../domain/api-response";
import { IProfession } from "../../domain/profession";
import { IProfessionModel } from "../../models/profession-model";

@Injectable({
  providedIn: "root",
})
export class ProfessionService {
  private static endpoint = EndpointBuilder.buildDomainEndpoint("professions");
  constructor(private readonly httpClient: HttpClient) {}

  getProfessions(): Observable<IApiResponse<IProfession[]>> {
    return this.httpClient.get<IApiResponse<IProfession[]>>(
      ProfessionService.endpoint
    );
  }

  createProfession(
    model: IProfessionModel
  ): Observable<IApiResponse<IProfession>> {
    return this.httpClient.post<IApiResponse<IProfession>>(
      ProfessionService.endpoint,
      model
    );
  }

  updateProfession(
    professionId: string,
    model: IProfessionModel
  ): Observable<IApiResponse<IProfession>> {
    return this.httpClient.patch<IApiResponse<IProfession>>(
      ProfessionService.singleEndpoint(professionId),
      model
    );
  }

  deleteProfession(professionId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      ProfessionService.singleEndpoint(professionId)
    );
  }

  private static singleEndpoint(id: string) {
    return ProfessionService.endpoint + "/" + id;
  }
}
