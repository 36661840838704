import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfessionService } from "./services/profession.service";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { ProfessionFacade } from "./state/profession.facade";
import { NgxsModule } from "@ngxs/store";
import { ProfessionState } from "./state/profession.state";
import { CoreModule } from "../core/core.module";
import { ProfessionsHelper } from "./helpers/professions.helper";
import { ProfessionTextPipe } from "./pipes/profession-text.pipe";

@NgModule({
  declarations: [ProfessionTextPipe],
  imports: [
    NgxsModule.forFeature([ProfessionState]),
    CommonModule,
    NgxsFormPluginModule,
    CoreModule,
  ],
  providers: [
    ProfessionFacade,
    ProfessionService,
    ProfessionsHelper,
    ProfessionTextPipe,
  ],
  exports: [ProfessionTextPipe],
})
export class ProfessionsCoreModule {}
