import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ProfessionFacade } from "../state/profession.facade";

@Injectable({
  providedIn: "root",
})
export class CanActivateProfessions  {
  constructor(private readonly professionFacade: ProfessionFacade) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.professionFacade.loadProfessions$();
  }
}
