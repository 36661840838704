export class LoadProfessions {
  static readonly type = "[Profession] Load professions";
  constructor() {}
}

export class ResetErrorMessage {
  static readonly type = "[Profession] Reset error message";
  constructor() {}
}

export class AddProfession {
  static readonly type = "[Profession] Add profession";
  constructor() {}
}

export class UpdateProfession {
  static readonly type = "[Profession] Update profession";
  constructor(public professionId: string) {}
}

export class DeleteProfession {
  static readonly type = "[Profession] Delete profession";
  constructor(public professionId: string) {}
}
